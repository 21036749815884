import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import './App.css';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function Basic(props) {
  const classes = useStyles();
  const [links, setLinks] = useState(getStored());
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  function getStored() {
    let read = localStorage.getItem('links');
    if (!read) return [];
    read = JSON.parse(read);
    return read;
  }
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const style_uploadButton = {
    width: '50%',
    margin: '0 auto',
    maxWidth: '150px'
  }
  
  const fileGetBinary = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject('file reading was aborted')
      reader.onerror = () => reject('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        let binaryStr = reader.result
        // console.log(binaryStr);
        // formData.append(file.name, binaryStr);
        binaryStr = binaryStr.split('base64,')[1]
        resolve(binaryStr);
      }
      // reader.readAsText(file)
      // reader.readAsArrayBuffer(file)
      reader.readAsDataURL(file)
    });
  }
  const upload = async () => {
    const formData = new FormData();
    console.log('acceptedFiles', acceptedFiles);
    for (const file of acceptedFiles) {
      console.log('file', file);
      const binaryStr = await fileGetBinary(file);
      console.log('name', file.name);
      console.log('path', file.path);
      console.log('binaryStr', binaryStr);
      const path = file.path.replace('/', ''); 
      // formData.append(path, file);
      formData.append(path, binaryStr);
    }

    fetch(`https://ads.hopla.to/upload`, {
      method: 'POST',
      body: formData
    }).then(x => x.text()).then(x => {
      let response;
      try {
        response = JSON.parse(x);
      } catch (e) {
        console.error("Failed to parse api response", e);
      }
      setLinks([...links, response.id]);
      localStorage.setItem('links', JSON.stringify([...links, response.id]));
    })
  }

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
      <Button onClick={upload} style={style_uploadButton} variant="contained" color="primary">
        Upload
      </Button>
      <div id="links" style={{textAlign: 'center', marginTop: '20px'}}>
        {links && links.map(link => {
          return (<div key={link}><a href={'https://ads.hopla.to/' + link} target="_blank" style={{textAlign: 'center'}}>https://ads.hopla.to/{link}/</a><br></br></div>)
        })}


    
      </div>
    </section>
  );
}

export default Basic